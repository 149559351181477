<template>
  <section v-if="!this.$store.state.page.isMobile" style="height: 980px;">
    <div id="contents_wrap">
      <div class="contents">
        <div class="tab_container">
          <ul class="mypTabs" style="margin-top: 30px; margin-left:70px;">
            <li><router-link  to="/info/user">내정보</router-link></li>
            <li><router-link  to="/info/bet">베팅내역</router-link></li>
            <li><router-link  to="/bbs/c/l">1:1 문의</router-link></li>
            <li><router-link class="on" to="/bbs/msg/l">쪽지함</router-link></li>
            <li><router-link  to="/info/recommender">추천인</router-link></li>
          </ul>
          <div id="tab4" class="tab_content fading" style="display: block;">
            <ul class="smk_accordion" style="display: block;">
              <li class="acc_section acc_active">
                <div class="acc_head"><h3>쪽지함</h3></div>
                <div class="acc_content" style="display: block;">

                  <div class="acc_content_in_2">
                    <div class="float_inline">
                      <div class="my_search_wrap">
                        <a @click="readAllNotes" class="btn3ci">전체 수신확인</a>
                        <a @click="deleteAllNotes" class="btn3cbi">전체 삭제</a>
                      </div>
                      <div class="my_search_list" id="qna-div" style="margin-top: 9px; display: block;">
                        <div id="qnaDt_wrapper" class="dataTables_wrapper no-footer">
                          <table id="qnaDt" class="dataTable no-footer" cellspacing="0" cellpadding="0" width="100%" role="grid" style="width: 100%;">
                            <thead>
                              <tr role="row">
                                <th class="sorting" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="제목: activate to sort column ascending" style="width: 498px;">제목</th>
                                <th class="sorting" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="보낸이: activate to sort column ascending" style="width: 249px;">보낸이</th>
                                <th class="sorting_desc" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="수신일시: activate to sort column ascending" style="width: 249px;" aria-sort="descending">수신일시</th>
                                <th class="sorting" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="확인일시: activate to sort column ascending" style="width: 249px;">확인일시</th>
                              </tr>
                            </thead>
                            <tbody>
                            <template v-for="(row, index) in lists">
                                <tr :key="'noets'+index">
                                  <td @click="readNotes(row, index)">{{ row.title }}</td>
                                  <td @click="readNotes(row, index)">관리자</td>
                                  <td @click="readNotes(row, index)">
                                    {{ row.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
                                  </td>
                                  <td v-if="!row.readAt">-</td>
                                  <td v-else @click="readNotes(row, index)">{{ row.readAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                                </tr>
                                <transition name="fade" :key="'notesContent'+index">

                                  <tr class="dt-txt" v-if="checkArray(index)">
                                    <td class="dt-txt" colspan="4" v-html="renderHtml(row.content)">
                                    </td>
                                  </tr>
                                </transition>
                              </template>
                          </tbody>
                          </table>
                          <Pagination v-if="pagination" :payload="payload" :pagination="pagination" :req-data="reqData" ></Pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

<!--            <div id="conf_modal1_delmsg" class="bg_mask_pop2 conf_modal">-->
<!--              <div class="bg_mask_pop_title">-->
<!--                <span class="popup_logo"><img src="/images/popup_logo.png"></span>-->
<!--                <span class="popup_close conf_modal_close"><img src="/images/popup_close.png"></span>-->
<!--              </div>-->
<!--              <div class="bg_mask_pop2_in">-->
<!--                <div class="pop_icon_center">-->
<!--                  <img src="/images/question_icon.png">-->
<!--                </div>-->
<!--                <div class="pop_text">-->
<!--                  메세지를 삭제 하시겠습니까?-->
<!--                </div>-->
<!--                <div class="btn_wrap">-->
<!--                  <span class="btn3 conf_modal_close">취소</span>-->
<!--                  <span class="btn3c conf_modal_yes ">확인</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>

        </div>
      </div>
    </div>
<!--    <div class="wrap">-->
<!--      <div class="bet_wrap">-->
<!--        <div class="example1">-->
<!--          <div class="panel panel-primary">-->
<!--            <form name="cbFormAll1" class="ng-pristine ng-valid">-->
<!--              <div id="tableAll1_wrapper" class="dataTables_wrapper no-footer">-->
<!--                <table id="tableAll1" cellspacing="0" cellpadding="0" width="100%" class="dataTable no-footer" role="grid" style="width: 100%;">-->
<!--                  <thead>-->
<!--                    <tr role="row">-->
<!--                      <th style="width: 144px;">번호</th>-->
<!--                      <th style="width: 191px;">제목</th>-->
<!--                      <th style="width: 189px;">상태</th>-->
<!--                      <th style="width: 130px;">수신일시</th>-->
<!--                      <th style="width: 168px;">확인일시</th>-->
<!--                      <th style="width: 143px;">삭제</th>-->
<!--                    </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                      <template v-for="(row, index) in lists">-->
<!--                        <tr :key="'noets'+index">-->
<!--                          <td>{{ index | makeIndex(payload.page, payload.size)}}</td>-->
<!--                          <td @click="readNotes(row, index)">{{ row.title }}</td>-->
<!--                          <td  @click="readNotes(row, index)">-->
<!--                            <span :class="{-->
<!--                                'font_014': !row.readAt,-->
<!--                                'font_012': row.readAt-->
<!--                            }">{{ row.readAt ? '확인' : '미확인' }}-->
<!--                            </span>-->
<!--                          </td>-->
<!--                          <td @click="readNotes(row, index)">-->
<!--                            {{ row.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}-->
<!--                          </td>-->
<!--                          <td @click="readNotes(row, index)">{{ row.readAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>-->
<!--                          <td>-->
<!--                            <button class="delt" @click="deleteNotes(row)"><i class="fas fa-trash"></i></button>-->
<!--                          </td>-->
<!--                        </tr>-->
<!--                        <transition name="fade" :key="'notesContent'+index">-->
<!--                          <tr v-if="checkArray(index)">-->
<!--                            <td colspan="6" class="answer_div" v-html="renderHtml(row.content)">-->
<!--                            </td>-->
<!--                          </tr>-->
<!--                        </transition>-->
<!--                      </template>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--                <Pagination v-if="pagination.end" :payload="payload" :pagination="pagination" :req-data="reqData"></Pagination>-->
<!--                <div class="btnBox02">-->
<!--                  <a @click="readAllNotes" class="btn04">전체 수신확인</a>-->
<!--                  <a @click="deleteAllNotes" class="btn05">전체 삭제</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </section>
  <div v-else style="background-color: #1c2021;">
    <div class="header2">
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>쪽지함</h2>
    </div>
    <div class="menu_2_2">
      <div style="width:100%">
        <div><router-link to="/info/user">내정보</router-link></div>
        <div><router-link to="/info/bet">베팅내역</router-link></div>
      </div>

      <div style="width:100%">
        <div><router-link to="/bbs/c/l">1:1문의</router-link></div>
        <div class="active"><router-link to="/bbs/msg/l">쪽지함</router-link></div>
      </div>
    </div>
    <div class="wrap">
      <div class="btnBox02 mobile_two_btn">
        <a @click="readAllNotes" class="btn04">전체 수신확인</a>
        <a @click="deleteAllNotes" class="btn05">전체 삭제</a>
      </div>
      <ul class="mobile_messages_ul">
        <template v-for="(row, index) in lists" >
          <li :key="'title'+index">
            <p @click="readNotes(row, index)">
              <span class="font_014" v-if="!row.readAt">미확인</span>
              <span class="font_012" v-else>확인</span>
              {{ row.title }}
            </p>
            <span>{{ row.createdAt | formatDate('YYYY-MM-DD') }}</span>
          </li>
          <li v-if="checkArray(index)" :key="'content'+index">
            <td v-html="renderHtml(row.content)"></td>
          </li>
        </template>
      </ul>
      <MPagination v-if="pagination" :payload="payload" :pagination="pagination" :req-data="reqData"></MPagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pagination04'
import MPagination from '../../components/MPagination'
export default {
  name: "Notes",
  components: {
    Pagination,
    MPagination
  },
  data: function(){
    return {
      path: 'notes/list',
      lists: [],
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null
      },
      openDetailNotes: [],
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData(){
      this.$store.dispatch('GET_NOTES', { payload: this.payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    readNotes: function(item, index){
      this.toggle(index)
      this.$store.commit('READ', item)
      this.$store.dispatch('READ_NOTES', { payload: { item }}).then(() => {
        this.$store.dispatch('ME');
        this.reqData();
      })
    },
    deleteNotes: function(item){
      if(confirm('삭제 하시겠습니까?')){
        return this.$store.dispatch('DELETE_NOTES', { payload: { item }})
            .then(data => {
              const result = data.data;
              alert(result.msg);
              this.$store.dispatch('ME');
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error);
            })
      }
    },
    deleteAllNotes: function(){
      if(confirm('전체 삭제 하시겠습니까?')){
        return this.$store.dispatch('DELETE_ALL_NOTES')
            .then(() => {
              this.$store.dispatch('ME');
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error);
            })
      }
    },
    readAllNotes: function(){
      if(confirm('전부 확인 하시겠습니까?')){
        this.$store.commit('READ_ALL')
        this.$store.dispatch('READ_ALL_NOTES')
            .then(() => {
              this.$store.dispatch('ME');
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error);
            })
      }
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      if (!str) return '';
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },

    toggle: function (index) {
      if (this.openDetailNotes.indexOf(index) >= 0) return this.openDetailNotes.splice(this.openDetailNotes.indexOf(index), 1)
      this.openDetailNotes.push(index)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailNotes.splice(index, 1);
      // return this.openDetailBet;
    },

    checkArray: function (id) {
      let result = this.openDetailNotes.indexOf(id) >= 0
      return result
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {float:left; width:100%;}
.contents {width:1330px; margin:0px auto; min-height:600px; }
.contents_in_m20 {width:100%; float:left; margin:0 0 20px 0}

/*fade*/
.tab_content.fading{
  opacity: 1;
  animation: fading 0.4s linear;
}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0; margin-left: 70px; margin-right: 70px;}

.fading {
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 705ms;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-duration: 705ms;
}

@keyframes fading {
  from { opacity: 0 }
  to { opacity: 1 }

}
.dt-txt{
  /*overflow-y: scroll!important;*/
  height: 100px!important;
  background: #202122!important;
  /*padding: 25px 10px 25px 10px!important;*/
  padding:0px!important;
  /*text-align: left!important;*/
  /*transition: all 0.1s ease;*/
}
div.slider {
  display: none;
  text-align: left!important;
  /*padding: 25px 10px 25px 10px!important;*/
  padding-left:10px!important;
}
#qnaDt tbody tr{
  cursor: pointer;
  transition: all .2s ease-in-out;
}
#qnaDt tbody tr td.dt-txt {
  text-align: left!important;
  padding-left: 10px!important;
}
table#qnaDt tbody tr:hover {
  background: #293133!important;
}

table.dataTable thead th{
  color: #c1c3c4;
  background: #2e3334;
  height: 40px;
  text-align: center;
  border-right: solid 1px #1c2021;
  border-bottom: none!important;
  font-size: 12px!important;
  font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif!important;
  color: #c1c3c4!important;
}
table.dataTable thead th:first-child{

  border-left: solid 1px #1c2021!important;

}
table.dataTable tbody tr {
  background-color: transparent!important;
  background: #262a2b!important;
}
table.dataTable tbody td {
  padding: 0px!important;
  text-align: center!important;
  border-left: solid 1px #1c2021!important;
  height: 35px!important;
  border-right: solid 1px #1c2021!important;
  font-size: 12px!important;
  font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif!important;
}
.dataTables_scrollBody{
  border-top: 1px solid #000000!important;
}

.smk_accordion { width:100%; position: relative; }
.smk_accordion .acc_section .acc_head {position: relative;background: #2d3232;padding:10px 0 10px 0;display: block;cursor: pointer; width:100%; text-indent:10px}
.smk_accordion .acc_section .acc_head h3 {line-height: 1;margin: 5px 0; font-size:14px; font-weight:700}
.smk_accordion .acc_section .acc_content {background:#191a1b; }
.smk_accordion .acc_section.acc_active > .acc_head {background: #222727;}
.smk_accordion li{margin:0 0 1px 0}
.acc_content_in_1 {padding:60px; background:#191a1b;}
.acc_content_in_2 {padding:40px; background:#191a1b; }
.acc_btn_wrap {width:100%;text-align:center; margin:20px 0 0 0 }

.btn3ci {
  width: 168px;
  height: 42px;
  margin-right: 2px;
  background: #da2c36;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  font-size: 16px;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: -1px;
  cursor: pointer;
}

.btn3cbi {
  width: 168px;
  height: 42px;
  background: #2e3334;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  font-size: 16px;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: -1px;
  cursor: pointer;
}

/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:#da2c36;
  font-weight: bold;
}

</style>
