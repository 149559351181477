const page = {
    namespaced: true,
    state:{
        currentPath: null,
        isMobile: false,
        menuActive: false,
        menu2Active: false,
        sites: [],
        sitename: 'viva',
        ipInfo: {},
        popup: [],
        sports: [],
        member: null
    },
    mutations: {
    },
    actions: {

    },
    getters: {

    }
};

export default page
