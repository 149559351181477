<template>
  <div>
    <section v-if="!this.$store.state.page.isMobile">
    <div class="nbox size">
      <ul class="mypTabs">
        <li><router-link to="/cash/in" class="on">충전신청</router-link></li>
        <li><router-link to="/cash/out">환전신청</router-link></li>
        <li><router-link to="/point/change">포인트전환</router-link></li>
      </ul>

      <p class="mypSubj"><a @click="toggleReq">충전신청</a></p>
      <transition name="fade">
        <div class="mypBox"  v-if="true" style="display: block;">
        <span class="line"></span>
        <div class="mypForm">
          <div class="fl">
            <div class="bank-chk">
              <table>
                <tbody><tr>
                  <th>입금은행</th>
                  <td>{{ this.bankName }}</td>
                  <th>계좌번호</th>
                  <template v-if="this.account.indexOf('http') > -1">
                    <td><a v-bind:href="this.account" target="_blank" style="color: #007bff;">{{ this.account }}</a></td>
                  </template>
                  <template v-else>
                    <td>{{ this.account }}</td>
                  </template>
                </tr>
                <tr>
                  <th>예금주</th>
                  <td colspan="3">
                    <div class="flex">
                      <span>{{ this.ownerName }}</span>
                      <!--<a v-if="user.members_grade === 1" @click="showModalPC = true" class="btn2">전용계좌 확인</a>-->
                      <a @click="reqAccount" class="btn2">전용계좌 확인</a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="item">
              <div class="inpBox" style="margin-right: 1px;">
                <span>보유금액</span><input type="text" class="inp ar" placeholder="0" style="width:150px;" :value="$options.filters.makeComma(user.members_cash)" disabled>&nbsp;<span>원</span>
              </div>
              <input type="number" class="inp ar" placeholder="0" style="width:230px" @focusout="amountRound" v-model="chargeForm.amount">
            </div>
            <div class="itemTxt">
              <p>※ 반드시 등록된 회원님의 환전계좌에서 입금시 충전가능합니다.</p>
            </div>
            <div class="bettingBtnList" style="width: 100% !important; margin: 0 0 0 0 !important;">
              <a @click="changeAmount(10000)" style="width: 16.4% !important;">1만원</a>
              <a @click="changeAmount(30000)" style="width: 16.4% !important;">3만원</a>
              <a @click="changeAmount(50000)" style="width: 16.4% !important;">5만원</a>
              <a @click="changeAmount(100000)" style="width: 16.4% !important;">10만원</a>
              <a @click="changeAmount(500000)" style="width: 16.4% !important;">50만원</a>
              <a @click="resetAmount" style="width: 16.4% !important;">정정</a>
            </div>
            <div class="itemTxt" style="margin-top: 10px;">
              <span style="margin-right: 8px; color: green;" >서비스를 받는다</span>
              <input style="margin-right: 20px;" type="radio" v-model="chargeForm.serviceType" :value="'y'">
              <span style="margin-right: 8px; color: red;" >서비스를 안받는다</span>
              <input type="radio" v-model="chargeForm.serviceType" :value="'n'">
            </div>
            <div class="bank-chk">
              <table>
                <tbody>
                <tr>
                  <th style="width: 35%;">보너스코드</th>
                  <td>
                    <input style="background: transparent; border: none;" type="text"  placeholder="보너스코드" v-model="chargeForm.code" @focusout="checkBonusCode">
                  </td>
                  <td>
                    <a href="https://t.me/+1YKXb9HKtFI4ZmE0" target="_blank"><img style="width: 35px;" src="@/assets/img/ico/telegram-icon.png"> 공지방</a>
                  </td>
                </tr>
                </tbody>
              </table>
              <div style="color: red; text-align: center; font-size: 20px;" v-if="showCodeText">{{ showCodeText }}</div>
            </div>
            <div class="btnBox03" v-if="!processing">
              <a @click="processDeposit" class="btnReq01">충전신청</a>
            </div>
            <div class="btnBox03" style="text-align: center;" v-else>
              <Loading></Loading>
            </div>
          </div>
          <div class="fr" >
<!--            <img style="cursor: pointer;" src="@/assets/img/main/lotto.png" alt="">-->
          </div>
        </div>
      </div>
      </transition>

      <p class="mypSubj">
        <a @click="toggleDescription">충전신청 관련 유의사항</a>
      </p>

      <transition name="fade">
        <div class="mypBox" v-if="true" style="display: block;">
          <ul class="txtList">
          <li>등록되어있는 환전계좌에서만 입금시 충전 처리 가능합니다. (입금자명 불일치시 충전처리불가)</li>
          <li>보안상 입금계좌는 수시로 변경될 수 있으니 필히 입금계좌 확인후 입금바랍니다.</li>
          <li>
            선입금후 충전신청을 하셔야 빠르게 처리가 가능합니다. 미입금후 충전시 취소처리 됩니다. <br>
            (입금 후 머니충전이 되지 않는다면 고객센터로 문의주시기 바랍니다.)
          </li>
          <li>충전신청시 최소 입금액은 10,000원 이상 가능하며, 금액 단위는 1,000원 단위 로만 신청 가능합니다</li>
          <li>본사는 수표 입금시 충전처리가 불가합니다. 반드시 참고하시기 바랍니다.</li>
          <li>첫충전 매충전 서비스를 받는다 -> *스포츠 100% 미니게임,로투스 200% 카지노 300%</li>
          <li>첫충전 매충전 서비스를 받지 않는다 -> *스포츠 100% 미니게임,로투스 100% 카지노 100%</li>
        </ul>

          <p class="tit">은행 점검 시간</p>
          <div class="bankList">
            <table>
              <tr>
                <th>국민은행</th>
                <td>23 : 30 ~ 00 : 30</td>
                <th>기업은행</th>
                <td>00 : 00 ~ 00 : 30</td>
                <th>농협</th>
                <td>23 : 30 ~ 00 : 30</td>
                <th>부산은행</th>
                <td>00 : 00 ~ 00 : 30</td>
              </tr>
              <tr>
                <th>수협</th>
                <td>23 : 30 ~ 00 : 30</td>
                <th>신한은행</th>
                <td>23 : 50 ~ 00 : 10</td>
                <th>새마을금고</th>
                <td>23 : 00 ~ 00 : 30</td>
                <th>우체국</th>
                <td>23 : 40 ~ 00 : 30</td>
              </tr>
              <tr>
                <th>우리은행</th>
                <td>23 : 30 ~ 01 : 00</td>
                <th>제주은행</th>
                <td>23 : 50 ~ 00 : 20</td>
                <th>하나은행</th>
                <td>23 : 30 ~ 01 : 00</td>
                <th>광주은행</th>
                <td>23 : 50 ~ 00 : 20</td>
              </tr>
              <tr>
                <th>경남은행</th>
                <td>23 : 30 ~ 01 : 00</td>
                <th>대구은행</th>
                <td>23 : 50 ~ 01 : 00</td>
                <th>삼성증권</th>
                <td>23 : 50 ~ 00 : 10</td>
                <th>시티은행</th>
                <td>23 : 30 ~ 01 : 00</td>
              </tr>
              <tr>
                <th>신협</th>
                <td>00 : 00 ~ 00 : 30</td>
                <th>산업은행</th>
                <td>23 : 00 ~ 01 : 00</td>
                <th>외환은행</th>
                <td>23 : 55 ~ 00 : 05</td>
                <th>전북은행</th>
                <td>23 : 00 ~ 01 : 00</td>
              </tr>
              <tr>
                <th>제일은행</th>
                <td>23 : 40 ~ 00 : 30</td>
              </tr>
            </table>
          </div>
        </div>
      </transition>

      <p class="mypSubj"><a @click="toggleList">머니 충전 리스트</a></p>
      <transition name="fade">
        <div class="tableWrap" v-if="true" style="display: block;">
        <table class="titleTable01 mt10">
          <colgroup>
            <col width="135" />
            <col width="287" />
            <col width="*" />
            <col width="287" />
            <col width="120" />
            <col width="120" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>충전금액</th>
            <th>입금자</th>
            <th>충전날짜</th>
            <th>상태</th>
            <th>삭제</th>
          </tr>
          <tr v-for="(row, index) in lists" :key="'depositList'+index">
            <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
            <td>{{ row.cash_amount | makeComma }}</td>
            <td>{{ row.cash_ownername }}</td>
            <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
            <td>
              {{ row.cash_status }}
            </td>
            <td>
              <button v-if="row.cash_status !== '접수'" class="btnReq04" @click="deleteDeposit(row)">삭제</button>
            </td>
          </tr>
        </table>
      </div>
      </transition>

    </div>
  </section>
  <div v-else style="background-color: #1c2021; height:2250px;">
    <div class="header2" >
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>충전</h2>
    </div>
    <div class="contents">
      <div class="sub_wrap">
        <div class="menu_3_2">
          <div style="width:100%">
            <div class="active"><router-link to="/cash/in">충전신청</router-link></div>
            <div><router-link to="/cash/out">환전신청</router-link></div>
            <div><router-link to="/point/change">포인트전환</router-link></div>
          </div>
        </div>
        <div class="tab_container">
          <div class="tab_content" style="display: block;">
            <ul class="smk_accordion">
              <li class="acc_section acc_active">
                <div id="TITLE_FORM" class="acc_head"><h3>충전신청</h3></div>
                <div class="acc_content" style="display: block;">
                  <div class="acc_content_in_1" style="padding-top: 10px">

                    <div class="bank-chk">
                      <table>
                        <tbody>
                          <tr>
                            <th>입금은행</th>
                            <td>{{ this.bankName }}</td>
                            <th>계좌번호</th>
                            <template v-if="this.account.indexOf('http') > -1">
                              <td>
                                <a id="accountlink" v-bind:href="this.account" target="_blank" style="color: #007bff;">{{ this.account }}</a>
                              </td>
                            </template>
                            <template v-else>
                              <td>{{ this.account }}</td>
                            </template>
                          </tr>
                        <tr>
                          <th>예금주</th>
                          <td colspan="3">
                            <div class="flex">
                              <span>{{ this.ownerName }}</span>
                              <!--<a v-if="user.members_grade === 1" @click="showModalMobile = true" class="btn2">전용계좌 확인</a>-->
                              <a @click="reqAccount" class="btn2">전용계좌 확인</a>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>


                    <div style="padding : 10px  10px 10px 10px;font-size: 14px;border: #505455 1px solid; line-height: 200%;display:none">
                      - <span style="color:#da2c36;font-weight: bold">24시간</span> 자유롭게 충전금액 관계없이 충전가능 <span style="color:#da2c36;font-weight: bold">(10만원 이상)</span><br>
                      - 반드시 <span style="color:#da2c36;font-weight: bold">입금 후 머니충전</span> 요청<br>
                      - 입금 시 <span style="color:#da2c36;font-weight: bold">1000만원 이상 고액</span> 일경우 <span style="color:#da2c36;font-weight: bold">1000만원 미만으로 분할</span> 하여 송금<br>
                      - <span style="color:#da2c36;font-weight: bold">타명의 계좌에서 이름변경 후 입금시 환전불가</span><br>
                      - 전용 입금계좌 노출 시 탈퇴 및 출금불가!
                    </div>
                    <br>



                    <form target="iframe_charge" name="form_charge" onsubmit="return false">
                      <input type="hidden" name="actype">
                      <input type="hidden" name="bank_name">
                      <input type="hidden" name="bank_num">
                      <input type="hidden" name="bank_owner">
                      <input type="hidden" name="charge_level">
                      <div class="cash_box">
                        <div class="cash_in" style="padding-bottom:10px;">
                          <div class="cash_1"><p style="float:left;padding:0 0 0 10px">보유금액</p><p style="float:right;padding:0 10px 0 0"><span class="font_002">{{ user.members_cash | makeComma }}</span> 원</p></div>
                          <div class="cash_2" style="height:30px;">
                            입금 먼저 하신 후 충전신청해 주세요
                          </div>
                        </div>
                        <div class="cash_in">
                          <div class="cash_4">
                            <input class="input_style01d" name="money" placeholder="충전금액" v-model="chargeForm.amount"  @focusout="amountRound">
                          </div>
                          <div class="cash_5">
                            최소 충전 가능 금액은 1만원 입니다.
                          </div>
                        </div>
                        <div class="cash_in">
                          <table width="100%" cellpadding="0" cellspacing="2">
                            <tbody>
                              <tr>
                                <td width="25%"><a><span class="btn1i" @click="changeAmount(10000)">1만원</span></a></td>
                                <td width="25%"><a><span class="btn1i" @click="changeAmount(50000)">5만원</span></a></td>
                                <td width="25%"><a><span class="btn1i" @click="changeAmount(100000)">10만원</span></a></td>
                                <td width="25%"><a><span class="btn1i" @click="changeAmount(1000000)">100만원</span></a></td>
                              </tr>
                              <tr style="height: 10px;"></tr>
                              <tr style="text-align: center;">
                                <td colspan="4">
                                  <span style="margin-right: 8px; color: green;" >서비스를 받는다</span>
                                  <input style="margin-right: 20px;" type="radio" v-model="chargeForm.serviceType" :value="'y'">
                                  <span style="margin-right: 8px; color: red;" >서비스를 안받는다</span>
                                  <input type="radio" v-model="chargeForm.serviceType" :value="'n'">
                                </td>
                              </tr>
                              <tr style="height: 10px;"></tr>
                              <tr class="bank-chk">
                                <td colspan="4">
                                  <table>
                                    <tbody>
                                    <tr>
                                      <th style="width: 25%;">보너스코드</th>
                                      <td colspan="2">
                                        <div class="flex">
                                          <input type="text" style="background-color:transparent; border: none;" v-model="chargeForm.code" @focusout="checkBonusCode">
                                        </div>
                                      </td>
                                      <td>
                                        <a href="https://t.me/+1YKXb9HKtFI4ZmE0" target="_blank"><img style="width: 35px;" src="@/assets/img/ico/telegram-icon.png"> 공지방</a>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  <div style="color: red; text-align: center; font-size: 20px; margin-bottom: 10px;" v-if="showCodeText">{{ showCodeText }}</div>
                                </td>
                              </tr>
                              <tr v-if="!processing">
                                <td colspan="2" width="50%"><a><span class="btn1i" @click="resetAmount">정정</span></a></td>
                                <td colspan="2" width="50%">
                                  <span class="btn1ci" @click="processDeposit">충전신청</span>
                                </td>
                              </tr>
                              <tr v-else style="text-align: center">
                                <td colspan="4">
                                  <Loading></Loading>
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td colspan="4" style="padding-top:10px;text-align: center;color:red">타인 명의 계좌에서 이름바꿔 신청시 환전불가!</td>
                              </tr>
                              <tr>
                                <td colspan="4" style="padding-top:10px;text-align: center;color:red">입금시  1000만원이상  금액은 1000만원 미만으로  분할송금</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
              <li class="acc_section">
                <div id="TITLE_LIST" class="acc_head k_open"><h3>충전신청 리스트</h3></div>
                <div class="acc_content" style="display: block;">
                  <div class="acc_content_in_1" id="LIST_ACCORDION">
                    <table class="list_table" cellspacing="0" cellpadding="0" width="100%">
                      <tbody>
                        <tr>
                          <td class="list_table_t" width="10%">번호</td>
                          <td class="list_table_t" width="40%">신청일시</td>
                          <td class="list_table_t" width="25%">금액</td>
                          <td class="list_table_t" width="25%">상태</td>
                        </tr>
                        <tr v-for="(row, index) in lists" :key="'test' + index">
                          <td class="list_table_center">{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
                          <td class="list_table_center">{{ row.cash_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                          <td class="list_table_center"><span class="font_002">{{ row.cash_amount | makeComma }}</span>원</td>
                          <td class="list_table_center font_009">{{ row.cash_status }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </li>
              <li class="acc_section">
                <div class="acc_head"><h3>충전신청 관련 유의사항</h3></div>
                <div class="acc_content" style="display: block;">
                  <div class="mypBox">
                    <ul class="txtList">
                      <li>등록되어있는 환전계좌에서만 입금시 충전 처리 가능합니다. (입금자명 불일치시 충전처리불가)</li>
                      <li>보안상 입금계좌는 수시로 변경될 수 있으니 필히 입금계좌 확인후 입금바랍니다.</li>
                      <li>
                        선입금후 충전신청을 하셔야 빠르게 처리가 가능합니다. 미입금후 충전시 취소처리 됩니다. <br>
                        (입금 후 머니충전이 되지 않는다면 고객센터로 문의주시기 바랍니다.)
                      </li>
                      <li>충전신청시 최소 입금액은 10,000원 이상 가능하며, 금액 단위는 1,000원 단위 로만 신청 가능합니다</li>
                      <li>본사는 수표 입금시 충전처리가 불가합니다. 반드시 참고하시기 바랍니다.</li>
                      <li>첫충전 매충전 서비스를 받는다 -> *스포츠 100% 미니게임,로투스 200% 카지노 300%</li>
                      <li>첫충전 매충전 서비스를 받지 않는다 -> *스포츠 100% 미니게임,로투스 100% 카지노 100%</li>
                    </ul>
                    <div class="cash_bank">
                      <span class="font_006">은행 점검 시간</span>
                      <table cellspacing="0" cellpadding="0" class="back_table">
                        <tbody><tr>
                          <td class="bank_name">국민은행</td>
                          <td class="bank_time">23 : 30 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">기업은행</td>
                          <td class="bank_time">00 : 00 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">농협</td>
                          <td class="bank_time">23 : 30 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">부산은행</td>
                          <td class="bank_time">00 : 00 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">수협</td>
                          <td class="bank_time">23 : 30 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">신한은행</td>
                          <td class="bank_time">00 : 00 ~ 00 : 10</td>
                        </tr>
                        <tr>
                          <td class="bank_name">새마을금고</td>
                          <td class="bank_time">23 : 00 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">우체국</td>
                          <td class="bank_time">23 : 40 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">우리은행</td>
                          <td class="bank_time">23 : 30 ~ 01 : 00</td>
                        </tr>
                        <tr>
                          <td class="bank_name">제주은행</td>
                          <td class="bank_time">23 : 50 ~ 00 : 20</td>
                        </tr>
                        <tr>
                          <td class="bank_name">하나은행</td>
                          <td class="bank_time">00 : 00 ~ 00 : 10</td>
                        </tr>
                        <tr>
                          <td class="bank_name">광주은행</td>
                          <td class="bank_time">23 : 50 ~ 00 : 20</td>
                        </tr>
                        <tr>
                          <td class="bank_name">경남은행</td>
                          <td class="bank_time">23 : 30 ~ 01 : 00</td>
                        </tr>
                        <tr>
                          <td class="bank_name">대구은행</td>
                          <td class="bank_time">23 : 50 ~ 01 : 00</td>
                        </tr>
                        <tr>
                          <td class="bank_name">삼성증권</td>
                          <td class="bank_time">23 : 50 ~ 00 : 10</td>
                        </tr>
                        <tr>
                          <td class="bank_name">시티은행</td>
                          <td class="bank_time">23 : 30 ~ 00 : 10</td>
                        </tr>
                        <tr>
                          <td class="bank_name">신협</td>
                          <td class="bank_time">00 : 00 ~ 00 : 30</td>
                        </tr>
                        <tr>
                          <td class="bank_name">산업은행</td>
                          <td class="bank_time">23 : 00 ~ 01 : 00</td>
                        </tr>
                        <tr>
                          <td class="bank_name">외환은행</td>
                          <td class="bank_time">23 : 55 ~ 00 : 05</td>
                        </tr>
                        <tr>
                          <td class="bank_name">전북은행</td>
                          <td class="bank_time">23 : 30 ~ 01 : 00</td>
                        </tr>
                        <tr>
                          <td class="bank_name">제일은행</td>
                          <td class="bank_time">23 : 40 ~ 00 : 30</td>
                        </tr>
                        </tbody></table>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- PC용 모달 -->
  <div v-show="showModalPC" class="modal-overlay" @click.self="closeModalPC">
      <div class="modal-content">
        <button @click="closeModalPC" class="close-button">닫기</button>
        <iframe :src="url" frameborder="0" style="width: 100%; height: 730px;"></iframe>
      </div>
    </div>

    <!-- 모바일용 모달 -->
    <div v-show="showModalMobile" class="modal-overlay" @click.self="closeModalMobile">
      <div class="modal-content">
        <button @click="closeModalMobile" class="close-button">닫기</button>
        <div class="iframe-container">
          <iframe :src="url" frameborder="0" style="width: 100%; height: 500px;" class="scaled-iframe"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SideBox from '../components/SideBox'
import Loading from '../components/Loading'
import {mapState} from "vuex";
import MSG from '@/contants/msg'

export default {
  name: "Deposit",
  components: {
    Loading,
    // SideBox
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      ownerName: '',
      bankName: '',
      showReq: false,
      showList: false,
      showDescription: false,
      processing: false,
      url: "https://wa-pay.net/payments?mid=jay048896",
      chargeForm: {
        amount: 0,
        serviceType: 'y',
        code:null,
      },
      payload: {
        page: 1,
        size: 10,
        type: '입금',
        detailType: '캐시',
      },
      showCodeText: null,
      pagination: {},
      showModalPC: false,
      showModalMobile: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.depositHistory
    }),
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData()
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('DEPOSIT_HISTORY', { payload: this.payload })
          .catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    // 충전 금액 변경
    changeAmount: function(amount){
      this.chargeForm.amount += amount;
    },
    resetAmount: function(){
      this.chargeForm.amount = 0;
    },
    closeModalPC() {
      console.log('끄기')
      this.showModalPC = false;
      console.log('상태 >> ', this.showModalPC)
    },
    closeModalMobile() {
      this.showModalMobile = false;
    },
    // 충전신청
    processDeposit: function(){
      if (this.chargeForm.amount < 10000) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.minProcessAmount);
      if (this.showCodeText) return this.$store.dispatch('SHOW_ERR_MSG', '유효한 코드를 입력해주세요.');
      this.processing = true;
      this.$store.dispatch('PROCESS_DEPOSIT', { payload: this.chargeForm })
          .then(() => {
            this.processing = false;
            this.$store.dispatch('SHOW_ERR_MSG', MSG.success.deposit);
          })
          .catch((err) => {
            this.processing = false;
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })

    },
    amountRound: function(){
      let result = Math.floor(this.chargeForm.amount / 1000)
      this.chargeForm.amount = result * 1000
    },
    // 계좌번호 확인
    reqAccount: function() {
      return this.$store.dispatch('REQ_ACCOUNT')
          .then(data => {
            const result = data.data;
            this.account = result.payload.bank.account;
            this.ownerName = result.payload.bank.ownerName;
            this.bankName = result.payload.bank.bankName;
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    toggleReq: function(){
      this.showReq = !this.showReq;
    },
    toggleDescription: function(){
      this.showDescription = !this.showDescription;
    },
    toggleList: function(){
      this.showList = !this.showList;
    },
    checkBonusCode: function(){
      if (!this.chargeForm.code || this.chargeForm.code === '') return this.showCodeText = null;
      this.$store.dispatch('CHECK_DEPOSIT_BONUS_CODE', { payload: this.chargeForm })
          .then((data) => {
            const result = data.data;
            if (!result.success) this.showCodeText = result.msg;
            if(result.success) {
              this.chargeForm.serviceType = 'y';
              this.showCodeText = null;
            }
          })
    }
  }
}
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  width: 100%;
  height: 500px; /* 모바일용 아이프레임 높이 */
}

.scaled-iframe {
  width: 100%;
  height: 100%;
  /* transform: scale(0.8); */
  transform-origin: top left; 
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.modal-content {
  background: white;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff5f5f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sub_title {width:100%;height:55px; line-height:55px; text-align:center; font-size:1.1em; color:#ffffff; background:#151818; font-weight:bold; position:relative;}
.arrow_back {position:absolute; left:5px; top:0}
.arrow_back img{height:25px; width:auto}

.sub_wrap {width:100%;  float:left; padding:0 0 30px 0; clear:both}
/* 3칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_3_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_3_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_3_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_3_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_3_2 DIV DIV.active{
  background-color: #252929;
}

.menu_3_2 DIV DIV.active a {
  color:#da2c36;
  font-weight: bold;
}






/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:#da2c36;
  font-weight: bold;
}

/* tab */
ul.tabs {float: left; height: 40px; border: 1px solid #2d3232; width: 100%;}
ul.tabs li {float: left; height: 40px;line-height: 40px;margin-bottom: -1px;background: #1c2021;border-right: 1px solid #2d3232;overflow: hidden;position: relative;width:49.7%;text-align:center;font-weight:bold;display:inline-block;}
ul.tabs li:last-child{border-right:none}
ul.tabs li a {background: #1c2021;color:#6b7a7a; width:100%; height:40px;display:inline-block;}
ul.tabs li a:hover {background: #1c2021;color:#6b7a7a; width:100%; height:40px;display:inline-block;}
.tabs li.active a:hover{background: #252929; color:#02a8e4 ;width:100%;  height:40px;display:inline-block;}
.tabs li.active a  {background: #252929; color:#02a8e4 ;border-bottom: none;width:100%; height:40px;display:inline-block;}
.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 10px 0 0 0;}

/* accordion */
.smk_accordion { width:100%; position: relative; }
.smk_accordion .acc_section .acc_head {position: relative;background: #2d3232 url("/design/images/arrow_bottom.png") center right 10px no-repeat;padding:0 3% 0 0;display: block; cursor: pointer; width:97%; text-indent:10px; }
.smk_accordion .acc_section .acc_head h3 {line-height: 1;  font-weight:500;height:40px; line-height:40px}
.smk_accordion .acc_section .acc_content {background:#191a1b; }
.smk_accordion .acc_section.acc_active > .acc_head {background: #222727 url("/design/images/arrow_top.png") center right 10px no-repeat;width:97%;padding:0 3% 0 0;}
.smk_accordion .acc_section.acc_active > .acc_head h3 {line-height: 1;  font-weight:500;height:40px; line-height:40px}
.smk_accordion li{margin:0 0 1px 0}
.acc_content_in_1 {padding:10% 3% 10% 3%; background:#191a1b; clear:both; position:relative}


/* 충전/환전/포인트/머니 */
.cash_box {width:100%; display:block}
.cash_in {width:100%; display:inline-block;}
.cash_1 {width:99.5%; height:38px; line-height:38px; background:#191a1b; float:left; border:solid 1px #505455; margin:0 0 10px 0}
.cash_2 {float:left;width:100%; height:40px;margin:0 0 10px 0; }
.cash_3 {float:left; width:100%; }
.cash_3 div{text-align:center }
.cash_4 {width:100%; height:40px; margin:0 0 10px 0; float:left; }
.cash_5 { float:left; color:#6b7f7f; margin:0 0 10px 0}
.cash_7 {float:left; color:#ffa800;margin:5px 0 10px 0}
.cash_8 {width:100%; float:left; height:40px;  color:#ffa800; margin:0 0 0 0}
.cash_in div{text-align:center }
.cash_warning {line-height:25px; width:100%; margin:0 0 30px 0}
.cash_bank {width:100%;}
.back_table {width:100%;border-top:solid 1px #2e3032; margin:20px 0 0 0 }
.bank_name {width:25%;color:#ffffff; background:#151516;border-bottom:solid 1px #2e3032; text-align:center; border-right:solid 1px #2e3032;height:40px ;border-left:solid 1px #2e3032}
.bank_time {width:25%;color:#ffa800; background:#191a1b;border-bottom:solid 1px #2e3032; text-align:center;height:40px; border-right:solid 1px #2e3032;height:40px  }

/* input */
input { font-family: Nanum Gothic; color:#ffffff}
input:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-moz-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-o-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
textarea:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */

input:focus {outline:none}
select:focus {outline:none}
.input_style01d {border:solid 1px #505455; background:none; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:327px;}
/* btn */
.btn1i {width:98%; height:40px; display:inline-block; background:#363e3f; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn1i:hover {background:#323435}
.btn1ci {width:98%; height:40px; display:inline-block; background:#da2c36; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn1ci:hover {background:#0d9fd4}
.btn2i{width:98%; height:40px; display:inline-block; background:#323232; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn2i:hover {background:#323435}

/* 게시판list */
.list_table {width:100%; margin:0 0 20px 0; border-top:solid 1px #da2c36; }
.list_table_center { height:40px; text-align:center;  border-bottom:solid 1px #2e3032}
.list_table_t {color:#ffffff; background:#151516; height:40px;  text-align:center; border-bottom:solid 1px #000000;}
.hand img{height:30px; width:auto }

.bank-chk{margin:0 0 11px;}
.bank-chk .txt { font-size:13px; line-height: 1.6; color: #ffb400;margin-bottom: 15px; }
.bank-chk .btn2{position:relative;top:-1px;display:inline-block;text-align:center;width:123px;height:42px;line-height:42px;background:#545758;color:#fff; font-size:14px; }
.bank-chk{margin:0 0 20px;}
.bank-chk table { width:100%; border-collapse: collapse; border-top:1px solid #565656; border-bottom: 1px solid #565656; margin-bottom: 10px;  }
.bank-chk table th { width:20%; text-align: left; padding-left: 15px; color: rgba(255,255,255,0.7); height: 45px; border-bottom: 1px solid #565656; border-right:1px solid #565656; background: #262a2b }
.bank-chk table td { padding-left: 15px; color: #fff; height: 45px; border-bottom: 1px solid #565656; padding-right:15px; }
.bank-chk table td + th { border-left: 1px solid #565656; }
.bank-chk .txt { font-size:13px; line-height: 1.6; color: #ffb400;margin-bottom: 15px; }
.bank-chk .btn2{position:relative;top:-1px;display:inline-block;text-align:center;width:100px;height:35px;line-height:33px;background:#fff;color:#555; font-size:13px; font-weight: 600;}
.bank-chk .flex { display: flex; justify-content: space-between; align-items: center; }

/* #accountlink {
  color: #007bff !important; cursor: pointer;
}

a:link #accountlink{
  color: #007bff;
    text-decoration: none;
}

a:active, a:focus, a:hover, a:link {
    color: #007bff;
    text-decoration: none;
} */
</style>
