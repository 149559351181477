<template>
    <section v-if="!this.$store.state.page.isMobile" id="contents_wrap">
      <div style="text-align:center;">
        <img src="@/assets/img/etc/KInplay.jpeg" style="margin-top: 30px;">
        <div style="margin-top: 30px">
            상기 규정 숙지 후 이용 바라오며, 미숙지로 인해 발생한 모든 상황에 대해 운영진은 도움드릴 수 없는 점을 알립니다.
        </div>
        <div class="m-game-wrap" v-if="!isLoading" style="text-align: center;">
            <div class="m-game-body">
                <div class="m-game">
                    <Loading></Loading>
                </div>
            </div>
        </div>
        <button v-else class="btn-go1" @click="openKplaySports(108)" style="margin-top:30px; margin-bottom:30px ">동의 및 실행</button>
      </div>
    </section>
    <div v-else>
      <div style="text-align:center;">
        <img src="@/assets/img/etc/KInplay.jpeg" style="margin-top: 30px;">
        <div style="margin-top: 30px">
            상기 규정 숙지 후 이용 바라오며, 미숙지로 인해 발생한 모든 상황에 대해 운영진은 도움드릴 수 없는 점을 알립니다.
        </div>
        <div class="m-game-wrap" v-if="!isLoading" style="text-align: center;">
            <div class="m-game-body">
                <div class="m-game">
                    <Loading></Loading>
                </div>
            </div>
        </div>
        <button v-else class="btn-go1" @click="openKplaySports(108)" style="margin-top:30px; margin-bottom:30px ">동의 및 실행</button>
      </div>
  </template>
  
  <script>
  import Loading from '../../../../components/Loading'
  import {mapState} from "vuex";
  import MSG from "@/contants/msg";

  export default {
    name: "KINPLAY",
    components: {
        Loading
    },
    data: function() {
        return {
            isLoading: true,
        }
    },
    computed: {
        menuVisible(){
            return this.$store.getters['MENU_VISIBLE'];
        },
        kInplayVisible(){
          return this.menuVisible?.find(e => e.name === 'K인플레이').visible
        },
        ...mapState({
            user: state => state.user.user,
            isMobile: state => state.page.isMobile,
        }),
    },
    methods: {
        openKplaySports: function(product_id){
        if (!this.kInplayVisible) return alert('K-인플레이는 점검 중입니다.');
        if (!this.user) return alert(MSG.isNotLogin);
        if (!this.isLoading) return alert('처리 중입니다. 잠시만 기다려주세요')
        this.isLoading = false
        const data = {
            "domain_url": window.location.protocol + "//" + window.location.host,
            product_id,
            "type": 0,
            "is_mobile": this.isMobile
        }

        this.$store.dispatch('GET_KPLAY_SPORTS', data)
            .then(data => {
                const result = data.data;
                if (result.url) {
                console.log()
                window.open(result.url, 'KPlay', '_blank')
                this.isLoading = true
                }
            })
            .catch(err => {
                alert(err.response.data.error);
            })
        },
    }
  }
  </script>
  
  <style>
  .contents {
    margin-top: 20px;
  }
  .btn-go1 { right:0; top:0; width:140px; height: 40px; border-radius:5px; background: #da2c36; text-align: center; font-size:13px; color: #fff; }
  </style>
  