<template>
  <div class="list-type1">
    <div class="item"
         v-for="(date, index) in this.uniqGameStartTime"
         :key="'gameIdx'+index"
    >
      <template v-for="(league, leagueIdx) in timeLeagueSort(date)">
        <div class="title" style="background-color: #262a2b;" :key="'leagueTitle'+ league + leagueIdx">
          <img v-if="lists.find(e => e.leagueSeq === league).sport.icon"
              :src="lists.find(e => e.leagueSeq === league).sport.icon"
              style="width: 15px;"
          >

          <img
              v-if="lists.find(e => e.leagueSeq === league).league.icon"
              :src="lists.find(e => e.leagueSeq === league).league.icon"
              style="width: 30px; height: 15px; margin-left: 10px;">
          <span class="mr10" style="margin-right: 10px; margin-left: 10px;">
            {{ lists.find(e => e.leagueSeq === league).location | locationName }} - {{ lists.find(e => e.leagueSeq === league).league | leagueName }}
          </span>
        </div>

        <template v-for="game in gameTimeLeagueSort(date, league)">
          <!-- 펼침 후 -->
          <div
              :class="{'before': !checkArray(game.id), 'after': checkArray(game.id)}"
              :key="'gameIndex'+game.id"
          >
            <template v-if="game.isSuspended === 'y'">
              <div class="box" :key="'koreanFolders'+ game.id">
                <div class="date">
                  <p>
                    &nbsp;
                    <span style="color: white; font-size:15px;"><strong>{{ game.startTime | formatDate('YY-MM-DD')}}</strong></span>
                    <span class="time" style="margin-left: 3px; font-size:15px;"><strong>{{ game.startTime | formatDate('HH:mm')}}</strong></span>
                  </p>
                </div>
                <div class="type">
                  승무패
                </div>
                <div class="bet-box">
                  <DetailLockHome :team="game.homeTeam"></DetailLockHome>
                  <DetailLockDraw></DetailLockDraw>
                  <DetailLockAway :team="game.awayTeam"></DetailLockAway>
                </div>
                <div class="status">
                  <CountDown :startDate="game.startTime"></CountDown>
                </div>
                <div class="toggle"></div>
              </div>

            </template>
            <template v-else>
              <template v-if="game.folders.find(e => e.isVisible === 'y')">
                <template v-for="(folder, index2) in game.folders">
                  <!-- 승무패-->
                  <transition name="fade" :key="'koreanFolders' + index2">
                    <div class="box"
                         :key="'koreanFolders'+ game.id +index2"
                         v-if="folder.isVisible === 'y'"
                         v-show="index2 === 0 || checkArray(game.id)"
                    >
                      <div class="date">
                        <p>
                          &nbsp;
                          <span style="color: white; font-size:15px;"><strong>{{ game.startTime | formatDate('YY-MM-DD')}}</strong></span>
                          <span class="time" style="margin-left: 3px; font-size:15px;"><strong>{{ game.startTime | minute }}</strong></span>
                        </p>
                      </div>
                      <div class="type">
                        {{ folder.market | marketName(folder.periodSequence) }}
                      </div>
                      <div class="bet-box" v-if="folder.isSuspended === 'y'">
                        <DetailLockHome
                            :bets="folder.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === 'Home' || e.name === 'Odd')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.homeTeam"
                        >

                        </DetailLockHome>
                        <DetailLockDraw
                            v-if="folder.bets.find(e => e.name === 'X')"
                            :bets="folder.bets.find(e => e.name === 'X')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                        >
                        </DetailLockDraw>
                        <div class="vs" :class="{'orange--text': folder.market.isHandicap === 'y' || folder.market.isOverUnder === 'y'}" v-else>
                          {{ folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'y' ? folder.line : folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'n' ? Number(folder.line) * -1 : 'VS'  }}
                        </div>
                        <DetailLockAway
                            :bets="folder.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'Away' || e.name === 'Even')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.awayTeam"
                        >
                        </DetailLockAway>
                      </div>
                      <div class="bet-box" v-else>

                        <DetailPriceHome
                            :bets="folder.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === 'Home')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.homeTeam"
                        >
                        </DetailPriceHome>
                        <DetailPriceDraw
                            v-if="folder.bets.find(e => e.name === 'X')"
                            :bets="folder.bets.find(e => e.name === 'X')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.homeTeam"
                        >
                        </DetailPriceDraw>
                        <div class="vs" :class="{'orange--text': folder.market.isHandicap === 'y' || folder.market.isOverUnder === 'y'}" v-else>
                          {{ folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'y' ? folder.line : folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'n' ? Number(folder.line) * -1 : 'VS'  }}
                        </div>
                        <DetailPriceAway
                            :bets="folder.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'Away' || e.name === 'Even')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.awayTeam"
                        >
                        </DetailPriceAway>
                      </div>
                      <div class="status">
                        <CountDown :startDate="game.startTime"></CountDown>
                      </div>
                      <div class="toggle" v-if="index2 === 0" >
                        <button
                            style="background-color:transparent; color: #da2c36; font-weight: bold;"
                            v-if="game.folders.length > 1"
                            :class="{'active':checkArray(game.id)}"
                            @click="toggleDetail(game.id)"
                        >
                          + {{ game.folders.filter(e => e.isVisible === 'y').length }}
                        </button>
                      </div>
                      <div class="toggle" v-else></div>
                    </div>
                    <div class="box"
                         :key="'koreanFolders'+ game.id +index2"
                         v-else
                         v-show="index2 === 0 || checkArray(game.id)"
                    >
                      <div class="date">
                        <p>
                          &nbsp;
                          <span style="color: white; font-size:15px;"><strong>{{ game.startTime | formatDate('YY-MM-DD')}}</strong></span>
                          <span class="time" style="margin-left: 3px; font-size:15px;"><strong>{{ game.startTime | minute }}</strong></span>
                        </p>
                      </div>
                      <div class="type">
                        {{ folder.market | marketName(folder.periodSequence) }}
                      </div>
                      <div class="bet-box" >
                        <DetailLockHome
                            :bets="folder.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === 'Home' || e.name === 'Odd')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.homeTeam"
                        >

                        </DetailLockHome>
                        <DetailLockDraw
                            v-if="folder.bets.find(e => e.name === 'X')"
                            :bets="folder.bets.find(e => e.name === 'X')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                        >
                        </DetailLockDraw>
                        <div class="vs" :class="{'orange--text': folder.market.isHandicap === 'y' || folder.market.isOverUnder === 'y'}" v-else>
                          {{ folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'y' ? folder.line : folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'n' ? Number(folder.line) * -1 : 'VS'  }}
                        </div>
                        <DetailLockAway
                            :bets="folder.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'Away' || e.name === 'Even')"
                            :folder="folder"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :game="game"
                            :team="game.awayTeam"
                        >
                        </DetailLockAway>
                      </div>
                      <div class="status">
                        <CountDown :startDate="game.startTime"></CountDown>
                      </div>
                      <div class="toggle" v-if="index2 === 0" >
                        <button
                            style="background-color:transparent; color: #da2c36; font-weight: bold;"
                            v-if="game.folders.length > 1"
                            :class="{'active':checkArray(game.id)}"
                            @click="toggleDetail(game.id)"
                        >
                          + {{ game.folders.filter(e => e.isVisible === 'y').length }}
                        </button>
                      </div>
                      <div class="toggle" v-else></div>
                    </div>
                  </transition>
                </template>

              </template>
              <template v-else>
                <div class="box" :key="'koreanFolders'+ game.id">
                  <div class="date">
                    <p>
                      &nbsp;
                      <span style="color: white; font-size:15px;"><strong>{{ game.startTime | formatDate('YY-MM-DD')}}</strong></span>
                      <span class="time" style="margin-left: 3px; font-size:15px;"><strong>{{ game.startTime | formatDate('HH:mm')}}</strong></span>
                    </p>
                  </div>
                  <div class="type">
                    승무패
                  </div>
                  <div class="bet-box">
                    <DetailLockHome :team="game.homeTeam"></DetailLockHome>
                    <DetailLockDraw></DetailLockDraw>
                    <DetailLockAway :team="game.awayTeam"></DetailLockAway>
                  </div>
                  <div class="status">
                    <CountDown :startDate="game.startTime"></CountDown>
                  </div>
                  <div class="toggle"></div>
                </div>

              </template>
            </template>

          </div>
        </template>
      </template>


    </div>
  </div>
</template>

<script>
import CountDown from "./CountDown";
// import DetailPrice from "@/components/Game/Sports/Korean/DetailPrice";
// import DetailLock from "@/components/Game/Sports/Korean/DetailLock";
import DetailPriceHome from "@/components/Game/Sports/Korean/DetailPriceHome";
import DetailPriceDraw from "@/components/Game/Sports/Korean/DetailPriceDraw";
import DetailPriceAway from "@/components/Game/Sports/Korean/DetailPriceAway";
import DetailLockHome from "@/components/Game/Sports/Korean/DetailLockHome";
import DetailLockDraw from "@/components/Game/Sports/Korean/DetailLockDraw";
import DetailLockAway from "@/components/Game/Sports/Korean/DetailLockAway";

export default {
  name: "Korean",
  props: ['lists', 'cartType', 'addCart', 'checkCart'],
  components: {
    CountDown,
    // DetailPrice,
    // DetailLock,
    DetailLockHome,
    DetailLockDraw,
    DetailLockAway,
    DetailPriceHome,
    DetailPriceDraw,
    DetailPriceAway
  },
  data: function () {
    return {
      openDetailBet: [],
    }
  },
  computed: {
    uniqGameStartTime: function(){
      const dates = this.lists.map(e => e.startTime);
      const time = this.$options.filters.uniq(dates)
      return time;
    }
  },
  created() {
    this.sortLeagues()
  },
  methods: {
    /*
    * 종목의 자세히 버튼
    */
    checkArray: function (id) {
      let result = this.openDetailBet.indexOf(id) >= 0
      return result
    },
    /*
     * 토글 액션
     */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    timeLeagueSort: function(date){
      const games = this.lists.filter(e => e.startTime === date);
      const leagues = games.map(e => e.leagueSeq);
      const leaguesId = this.$options.filters.uniq(leagues);
      return leaguesId;
    },
    sortLeagues: function(){
      for(const u in this.uniqGameStartTime){
        this.timeLeagueSort(this.uniqGameStartTime[u]);
      }
    },
    gameTimeLeagueSort: function(date, league){
      const games = this.lists.filter(e => e.startTime === date && e.leagueSeq === league);
      return games;
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
