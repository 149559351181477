<template>
  <section class="customer" v-if="!this.$store.state.page.isMobile">
<!--    <ul class="locationList">-->
<!--      <li>HOME</li>-->
<!--      <li>공지사항</li>-->
<!--    </ul>-->
<!--    <h2 class="subTitle">공지사항</h2>-->

    <div id="contents_wrap" class="notice_tab">
      <div class="contents">
        <ul class="tabs">
          <li ><a @click="changeType(['notice', 'event'])" :class="{'on': Array.isArray(payload.type)}">전체</a></li>
          <li><a @click="changeType('notice')" :class="{'on': payload.type === 'notice'}">공지사항</a></li>
          <li><a @click="changeType('event')" :class="{'on': payload.type === 'event'}">이벤트</a></li>
        </ul>
        <div class="tab_container">
          <div id="tab1" class="tab_content" style="display: block;">
            <transition name="fade">
              <div v-if="isLoading" class="pictureView viewchange picflex">
                <div v-for="(row, index) in lists" :key="'notice'+index"
                     class="resultitem"
                     :class="{'open': expandedArticle.indexOf(index) >= 0, 'NOTICE': row.board_type === 'notice', 'EVENT': row.board_type === 'event'}"
                     :style="{'height': expandedArticle.indexOf(index) >= 0 ? '755px': '260px'}"
                >
                  <div class="box thumbnailurl" data-pos="206" @click="toggleArticle(index)">
                    <a class="clickevent notice_event_show" data-id="2439">
                      <img style="width: 300px; height: 180px;" :src="row.board_img">
                    </a>

                    <div class="clickevent  notice_img">
                      <div style="font-size: 30px;"></div>
                    </div>

                    <div class="notice_text">
                      <p class="notice_text_t">{{ row.board_title }}</p>
                    </div>
                  </div>
                  <div class="detailview container-fluid" style="z-index: 100;">
                    <div class="resulttitle">
                      <div class="view_center">
                        <div class="view_c_in_t">
                          <div class="view_title">
                            <p class="view_t">{{ row.board_title }}</p>

                          </div>
                          <div class="controllers">
                            <a @click="toggleArticle(index)" class="detailClose remove"><img src="@/assets/img/ico/popup_close.png"></a>
                          </div>
                        </div>
                        <div class="view_c_in" >
                          <div class="view_c_in_img"><img :src="row.board_img"></div>
                          <div class="view_c_in_text" style="height: 300px; overflow-y: scroll;" >
                            <div v-html="renderHtml(row.board_content)"></div>
                          </div>
                          <div id="all_2439" class="view_c_in_text" style="font-size:17px; font-weight: 600;"> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

      </div>
    </div>
  </section>
  <div v-else style="background-color: #1c2021;">
    <div class="header2">
      <h2>공지사항</h2>
    </div>
    <div class="tabs1">
      <a @click="changeType(['notice', 'event'])" :class="{'active': Array.isArray(payload.type)}">전체</a>
      <a @click="changeType('notice')" :class="{'active': payload.type === 'notice'}">공지사항</a>
      <a @click="changeType('event')" :class="{'active': payload.type === 'event'}">이벤트</a>
    </div>

    <div class="bg-content" style="background: #252929;">
      <div class="notice event">
        <ul>
          <li v-if="!lists" style="background: #252929;">
            <div style="text-align: center;">
              <Loading></Loading>
            </div>
          </li>
          <li v-if="lists && lists.length === 0" style="background: #252929;">
            <a class="q">
              <span>
                작성된 글이 없습니다.
              </span>
            </a>
          </li>
          <li v-else v-for="(row, index) in lists" :key="'customer'+index" style="background: #252929;">
            <a @click="toggleArticle(index)" class="q" :class="{'active': expandedArticle.indexOf(index)>=0}">
              <span>{{ row.board_title }}</span>
              <p class="img">
                <img :src="row.board_img">
              </p>
            </a>
            <div class="cont" v-html="renderHtml(row.board_content)" v-if="expandedArticle.indexOf(index)>=0" style="display: block">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
// import Pagination from '../../../components/Pagination01'
// import SideLink from '../../../components/Board/SideLink'
import Loading from '../../../components/Loading'

export default {
  name: "Event",
  components: {
    // Pagination,
    // SideLink,
    Loading
  },
  data: function(){
    return {
      path: 'bbs/n/l',
      lists: null,
      isLoading: false,
      payload: {
        page: 1,
        size: 20,
        keyword: null,
        type: ['notice', 'event'],
        sitename: 'viva'
      },
      expandedArticle: []
    }
  },
  mounted: function() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      this.isLoading = false;
      return this.$store.dispatch('GET_BOARD', { payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.isLoading = true;
            console.log('페이로드', Array.isArray(this.payload.type))
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    changeType (type){
      this.payload.type = type
      this.reqData(this.payload);
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/<img/g, '<img ')
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggleArticle: function(index) {
      if(this.expandedArticle.indexOf(index)>=0) {
        this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1);
      }
      else this.expandedArticle.push(index);
    },
  }
}
</script>

<style scoped>
/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {float:left; width:100%; padding:0 0 50px 0; background-color: #1c2021;}
.contents {width:1330px; margin:0px auto; min-height:600px; }
.contents_in_m20 {width:100%; float:left; margin:0 0 20px 0}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0;}

/*fade*/
.tab_content.fading{
  opacity: 1;
  animation: fading 0.4s linear;
}

.fading {
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 705ms;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-duration: 705ms;
}

@keyframes fading {
  from { opacity: 0 }
  to { opacity: 1 }

}

.notice_img{width:300px; height:180px; display: block;}
.notice_img img{width:300px; height:180px;}
.notice_text {text-align:center; width:280px;padding:0 10px 0 10px;  display: block;}
.notice_text_t {color:#ffffff; margin:20px 0 0 0}
.notice_text_c { margin:10px 0 0 0}
.view_c_in_img {margin:0 30px 0 0; float:left;}
.view_c_in_t {width:100%; margin:0 0 20px 0; float:left;}
.view_c_in {width:100%;}
.view_title {float:left; padding:30px 0 0 0 }
.view_t {font-size:30px; color:#ffffff; letter-spacing:-1px; line-height:35px; text-align:left}
.view_center {width:1330px; margin:0px auto}
.view_date {margin:10px 0 0 0; text-align:left}
.view_c_in_text { text-align:left}
.viewchange .resultitem {vertical-align: top;display: block; margin: 10px 12px 0;position: relative;text-align:center}
.viewchange .resultitem .box {vertical-align: top;display: block;overflow: hidden; background-size: cover; position: relative;cursor: pointer;}
.viewchange .resultitem>.box>a.clickevent { position: absolute;top: 0px; bottom: 0px;left: 0px;right: 0px;height: auto;}
.picflex {display: flex; flex-wrap: wrap;}
.picflex .resultitem {position: static;width: auto;border: 0;height: 260px;flex-grow: 1;}
.picflex .resultitem .box {border: none;width: 300px; height: 260px;line-height: normal; }
.picflex .resultitem.open {height: 675px;}
.picflex .resultitem.open:after {content: "";font-size: 0px;width: 0;height: 0;border-style: solid;border-width: 0px 20px 20px 20px; border-color: transparent transparent #121516 transparent; top: -10px;position: relative;}
.picflex .detailview {display: none; background: #121516;height: 395px;position: absolute;left: 0;right: 0;margin-top: 20px;overflow: auto;}
.picflex .resultitem.open .detailview {display: block;}
.controllers {text-align: right;}
.controllers a {display: inline-block;margin:30px 0 0 0;}

.picflex .resultitem{
  max-width: 308px!important;
}

.view_c_in_img img{
  width: 300px;
  height: 180px;
}
.view_c_in_img{
  margin:0px!important;
  width: 30%!important;
  float: left;
  text-align: left;

}
.view_c_in_text{
  width: 70%!important;
  float:right!important;
  margin-bottom: 20px;

}

.view_c_in_text p{
  padding:6px;
}
.picflex .detailview{
  min-height: 396px;
  height: auto!important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
