<template>
  <div class="content">
    <!-- 방송 //-->
    <div class="game_wrapper">
      <iframe :src="`${type}`" width="100%" height="641" scrolling="no" frameborder="0"></iframe>
    </div>
    <div class="row center">
      <div class="btn_action type3"><a style="cursor: pointer;"><span>회차 새로고침</span></a></div>
    </div>



    <div class="h40"></div>

    <div class="table_lotus_ba">
      <div class="step">{{ game.game_starttime | formatDate('MM/DD HH:mm:ss') }} &nbsp;[{{ game.game_round }}회차]</div>
      <div class="time theme_cl2 is-countdown" id="Clock1"><span class="countdown-row countdown-amount" style="margin-right: 10px;">{{ minute }}:{{ second }}</span></div>
    </div>


    <div class="power_room1">
      <div class="power_p_odd" :class="{'selected' : checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/홀짝') >= 0).bets.find(b => b.bets_name === '홀'))}" id="41338952_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/홀짝') >= 0).bets.find(b => b.bets_name === '홀'), game.folders.find(f => f.folders_detail.indexOf('파워볼/홀짝') >= 0))">파워볼 홀</a><p>1.95</p></div>
      <div class="power_p_even" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/홀짝') >= 0).bets.find(b => b.bets_name === '짝'))}" id="41338952_2" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/홀짝') >= 0).bets.find(b => b.bets_name === '짝'), game.folders.find(f => f.folders_detail.indexOf('파워볼/홀짝') >= 0))">파워볼 짝</a><p>1.95</p></div>
    </div>

    <div class="power_room2">
      <div class="power_n_big" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(b => b.bets_name === '대'))}" id="41338955_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(b => b.bets_name === '대'), game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0))">대 2.80</a></div>
      <div class="power_n_middle" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(b => b.bets_name === '중'))}" id="41338955_3" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(b => b.bets_name === '중'), game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0))">중 2.60</a></div>
      <div class="power_n_small" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(b => b.bets_name === '소'))}" id="41338955_2" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(b => b.bets_name === '소'), game.folders.find(f => f.folders_detail.indexOf('일반볼/소중대') >= 0))">소 2.80</a></div>
    </div>

    <div class="power_room3">
      <div class="power_n_odd" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(b => b.bets_name === '홀'))}" id="41338951_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(b => b.bets_name === '홀'), game.folders.find(f => f.folders_detail.indexOf('일반볼/홀짝') >= 0))">숫자합 홀</a><p>1.95</p></div>
      <div class="power_n_even" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(b => b.bets_name === '짝'))}" id="41338951_2" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(b => b.bets_name === '짝'), game.folders.find(f => f.folders_detail.indexOf('일반볼/홀짝') >= 0))">숫자합 짝</a><p>1.95</p></div>
    </div>

    <div class="power_room4">
      <div class="power_p_un" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(b => b.bets_name === '언더'))}" id="41338953_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(b => b.bets_name === '언더'), game.folders.find(f => f.folders_detail.indexOf('파워볼/언더오버') >= 0))">파워볼 언더</a><p>1.95</p><w>4.5</w></div>
      <div class="power_p_ov" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(b => b.bets_name === '오버'))}" id="41338953_2" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(b => b.bets_name === '오버'), game.folders.find(f => f.folders_detail.indexOf('파워볼/언더오버') >= 0))">파워볼 오버</a><p>1.95</p><w>4.5</w></div>
    </div>

    <div class="power_room5">
      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '홀+오버'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '홀+오버'), game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0))">일반볼 홀 + 오버 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '홀+언더'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '홀+언더'), game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0))">일반볼 홀 + 언더 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '짝+오버'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '짝+오버'), game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0))">일반볼 짝 + 오버 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '짝+언더'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(b => b.bets_name === '짝+언더'), game.folders.find(f => f.folders_detail.indexOf('일반볼/조합') >= 0))">일반볼 짝 + 언더 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '홀+오버'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '홀+오버'), game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0))">파워볼 홀 + 오버 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '홀+언더'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '홀+언더'), game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0))">파워볼 홀 + 언더 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '짝+오버'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '짝+오버'), game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0))">파워볼 짝 + 오버 3.70</a></div>

      <div class="power_sid_a" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '짝+언더'))}" id="41338956_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(b => b.bets_name === '짝+언더'), game.folders.find(f => f.folders_detail.indexOf('파워볼/조합') >= 0))">파워볼 짝 + 언더 3.70</a></div>
    </div>




    <div class="power_room6">
      <div class="power_n_un" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/언더오버') >= 0).bets.find(b => b.bets_name === '언더'))}" id="41338954_1" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/언더오버') >= 0).bets.find(b => b.bets_name === '언더'), game.folders.find(f => f.folders_detail.indexOf('일반볼/언더오버') >= 0))">일반볼 언더</a><p>1.95</p><w1>72.5</w1></div>
      <div class="power_n_ov" :class="{'selected': checkCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/언더오버') >= 0).bets.find(b => b.bets_name === '오버'))}" id="41338954_2" ><a @click="addCart(game.folders.find(f => f.folders_detail.indexOf('일반볼/언더오버') >= 0).bets.find(b => b.bets_name === '오버'), game.folders.find(f => f.folders_detail.indexOf('일반볼/언더오버') >= 0))">일반볼 오버</a><p>1.95</p><w1>72.5</w1></div>
    </div>

  </div>
</template>

<script>
// import moment from "moment";

import moment from "moment-timezone";

export default {
  name: "PowerBallFolders",
  props: ['game', 'initData', 'type'],
  data: () => {
    return {
      gameStatus: false,
      minute: 0,
      second: 0,
      endTime: 10,
    }
  },
  mounted() {
    this.interval = setInterval(this.remaining, 1000);
  },
  methods: {
    // 베팅카트에 담기
    addCart(bet, folder){
      const item = {
        gameSeq: this.game.game_seq,
        gameId: this.game.game_id,
        odds: folder.folders_detail === '일반볼/소중대' && (bet.bets_name === '소' || bet.bets_name === '대') ? 2.8 : folder.folders_detail === '일반볼/소중대' && (bet.bets_name === '중') ? 2.6 : folder.folders_detail.indexOf('조합') >= 0 ? 3.8 : bet.bets_price,
        betsName: bet.bets_name,
        betsSeq: bet.bets_seq,
        betType: '미니게임',
        marketNameEn: folder.folders_detail,
        marketNameKr: folder.folders_detail,
        leagueNameEn: '파워볼',
        leagueNameKr: '파워볼',
        sportNameEn: '파워볼',
        sportNameKr: '파워볼',
        round: this.game.game_round,
        startTime: this.game.game_starttime,
        detail: this.game.game_detail,
      }
      this.$store.dispatch('ADD_BET_MINI', item)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets) {
      if (this.$store.state.minigame.cart && this.$store.state.minigame.cart.betsSeq === bets.bets_seq) return true
      return false
    },
    remaining() {
      if (this.game) {
        let startTime = moment(this.game.game_starttime);
        let now = moment().add(9, "hour"); // 한국시간으로 치환
        let remaining = moment.duration(startTime - now);
        this.remainSeconds = startTime.diff(now, 'seconds');
        this.minute = remaining.minutes();
        this.second = remaining.seconds() >= 0 ? remaining.seconds() : 0;

        const gameStartTime = startTime.unix();
        const calcNow = now.unix();
        if (this.endTime < gameStartTime - calcNow) {
          this.betPossible = false;
        } else {
          this.$store.dispatch('CLEAR_MINI');
          this.betPossible = true;
        }
        if (gameStartTime - calcNow === -1 || gameStartTime - calcNow === 0) {
          this.initData();
        }
      }
    }

  }
}
</script>

<style scoped>

.content{
  position:relative;
  width:78%;
  padding-right:2%;
  float:left;
  min-height: 400px;
}

.game_wrapper{
  padding:20px;
  box-sizing: border-box;
  margin:0 auto;
  text-align: center;
  width:100%;
  background:rgba(0,0,0,0.5);
}

.center{
  text-align:center;
  margin: 0 auto;
}

.btn_action {
  vertical-align: top;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-top:30px;
}

.btn_action.type3 a{
  min-width: 120px;
  padding:0px 40px;
}

.btn_action a{
  text-align: center;
  width:100%;
  height:100%;
  line-height: 40px;
  padding:0px 100px;
  box-sizing: border-box;
  background: #da2c36;
  border:1px solid rgba(255,255,255,0.3);
  display: block;
  transition:background .3s ease-out;
}

.h40{
  position: relative;
  height:40px;
}

.table_lotus_ba {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #da2c36;
  border-left: 1px solid #da2c36;
  border-right: 1px solid #da2c36;

  padding-left: 10px;
  height: 42px;
  clear: both;
}
.table_lotus_ba .step, .table_lotus_ba .time {
  width: 50%;
  float: left;
  line-height: 42px;
  font-size: 15px;
  font-weight: bold;
}
.table_lotus_ba .theme_cl2 {
  color: #da2c36;
}
.table_lotus_ba .time {
  text-align: right;
  font-weight: 300;
}


/* 파워볼 */
.power_room1{height: 130px; width: 40%; float: left; }
.power_room2{height: 130px; width: 20%; float: left; }
.power_room3{height: 130px; width: 40%; margin-bottom: 20px; }
.power_room4{height: 130px; width: 40%; float: left; }
.power_room5{height: 130px; width: 20%; float: left; }
.power_room6{height: 130px; width: 40%; float: left; }
.power_room7{height: 130px; width: 40%; float: left; }
.power_room8{height: 130px; width: 20%; float: left; }
.power_room9{height: 130px; width: 40%;}
.power_room10{height: 130px; width: 40%; float: left; }
.power_room11{height: 130px; width: 20%; float: left; }
.power_room12{height: 130px; width: 40%; float: left; margin-bottom: 30px;}

.power_p_odd {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;}
.power_p_even {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 599px; top: -131px;}
.power_n_odd {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;}
.power_n_even {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 599px; top: -131px;}
.power_n_big {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; border-top: 2px solid #da2c36; height: 87px;}
.power_n_middle {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 87px;}
.power_n_small {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 87px;}

.power_n_oddbig {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 87px;top: 90px;}
.power_n_oddmiddle {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 87px;top: -85px;}
.power_n_oddsmall {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; border-top: 2px solid #da2c36; height: 87px;top: 2px;}
.power_n_evenbig {width: 100%;box-sizing: border-box;padding: 20px 0px;text-align: center;position: relative;border-right: 1px solid rgba(255,255,255,0.2);border-bottom: 1px solid rgba(255,255,255,0.2);float: left;height: 87px;top: 89px;left: -399px;}
.power_n_evenmiddle {width: 100%;box-sizing: border-box;padding: 20px 0px;text-align: center;position: relative;border-right: 1px solid rgba(255,255,255,0.2);border-bottom: 1px solid rgba(255,255,255,0.2);float: left;height: 87px;top: -85px;left: -399px;}
.power_n_evensmall {width: 100%; box-sizing: border-box; padding: 20px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; border-top: 2px solid #da2c36; height: 87px;top: 2px;left: -399px;}


.power_p_un {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;top: 0px;left: -399px;}
.power_p_ov {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 200px; top: -131px;}
.power_sid_a {width: 100%; box-sizing: border-box; padding: 5px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-top: 2px solid #da2c36; border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 32px;    left: -399px;}
.power_sid_b {width: 100%; box-sizing: border-box; padding: 10px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 65.5px;    left: -399px;}
.power_sid_c {width: 100%; box-sizing: border-box; padding: 10px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 65.5px;    left: -399px;}
.power_sid_d {width: 100%; box-sizing: border-box; padding: 10px 0px; text-align: center; position: relative; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); float: left; height: 65.5px;    left: -399px;}
.power_n_un {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: -399px; top: 1px;}
.power_n_ov {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 200px; top: -130px;}

.power_p_oddun {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;top: 2px;left: 0px;}
.power_p_oddov {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 599px; top: -129px;}
.power_p_evenun {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;top: 3px;left: 0px;}
.power_p_evenov {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 599px; top: -128px;}

.power_n_oddun {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;top: 2px;left: -399px;}
.power_n_oddov {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 200px; top: -128px;}
.power_n_evenun {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-left: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left;top: 2px;left: -399px;}
.power_n_evenov {width: 100%; box-sizing: border-box; border-right: 1px solid rgba(255,255,255,0.2); border-bottom: 1px solid rgba(255,255,255,0.2); padding: 20px 0px; text-align: center; border-top: 2px solid #da2c36; position: relative; float: left; left: 200px; top: -128px;}

.power_p_un a, .power_p_ov a, .power_n_un a, .power_n_ov a{text-indent: 10px; position: relative; border-radius: 8px; padding: 18px 20px; display: inline-block; width: 225px; font-weight: 100; line-height: 50px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); font-size: 40px;}
.power_sid_a a, .power_sid_b a, .power_sid_c a, .power_sid_d a{text-indent: 10px; position: relative; border-radius: 8px; padding: 0px 10px; display: inline-block; font-weight: 100; line-height: 22px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); width: auto; max-width: 154px; font-size: 15px;}

.power_p_oddun a, .power_p_oddov a, .power_n_oddun a, .power_n_oddov a{text-indent: 10px; position: relative; border-radius: 8px; padding: 18px 20px; display: inline-block; width: 225px; font-weight: 100; line-height: 50px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); font-size: 33px;}
.power_p_evenun a, .power_p_evenov a, .power_n_evenun a, .power_n_evenov a{text-indent: 10px; position: relative; border-radius: 8px; padding: 18px 20px; display: inline-block; width: 225px; font-weight: 100; line-height: 50px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); font-size: 33px;}

.power_p_odd a, .power_p_even a, .power_n_odd a, .power_n_even a{text-indent: 10px; position: relative; border-radius: 8px; padding: 18px 20px; display: inline-block; width: 200px; font-weight: 100; line-height: 50px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); font-size: 40px;}
.power_n_big a, .power_n_middle a, .power_n_small a{text-indent: 10px; position: relative; border-radius: 8px; padding: 11px 20px; display: inline-block; font-weight: 100; line-height: 22px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); width: auto; padding-right: 10px; padding-left: 10px; font-size: 24px; max-width: 200px;}

.power_n_oddbig a, .power_n_oddmiddle a, .power_n_oddsmall a{text-indent: 10px; position: relative; border-radius: 8px; padding: 11px 20px; display: inline-block; font-weight: 100; line-height: 25px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); width: auto; padding-right: 10px; padding-left: 10px; font-size: 15px; max-width: 200px;}
.power_n_evenbig a, .power_n_evenmiddle a, .power_n_evensmall a{text-indent: 10px; position: relative; border-radius: 8px; padding: 11px 20px; display: inline-block; font-weight: 100; line-height: 25px; background: url('~@/assets/img/etc/btn_bg2.jpg') center center no-repeat; background-size: cover; border: 1px solid rgba(255,255,255,0.35); width: auto; padding-right: 10px; padding-left: 10px; font-size: 15px; max-width: 200px;}

.power_p_odd p , .power_p_even p , .power_n_odd p , .power_n_even p {right: 15px; top: 38px; position: absolute; }
.power_p_un p , .power_p_ov p , .power_n_un p , .power_n_ov p {right: 15px; top: 38px; position: absolute; }

.power_p_oddun p , .power_p_oddov p , .power_n_oddun p , .power_n_oddov p {right: 15px; top: 38px; position: absolute; }
.power_p_evenun p , .power_p_evenov p , .power_n_evenun p , .power_n_evenov p {right: 15px; top: 38px; position: absolute; }

.power_p_un w , .power_p_ov w , .power_n_un w , .power_n_ov w {left: 186px; top: 18px; position: absolute; font-size: 20px; color: #E3CEF6;}
.power_p_un w1 , .power_p_ov w1 , .power_n_un w1 , .power_n_ov w1 {left: 182px; top: 19px; position: absolute; font-size: 20px; color: #E3CEF6;}

.power_p_oddun w , .power_p_oddov w , .power_n_oddun w , .power_n_oddov w {left: 186px; top: 18px; position: absolute; font-size: 20px; color: #E3CEF6;}
.power_p_oddun w1 , .power_p_oddov w1 , .power_n_oddun w1 , .power_n_oddov w1 {left: 182px; top: 19px; position: absolute; font-size: 20px; color: #E3CEF6;}
.power_p_evenun w , .power_p_evenov w , .power_n_evenun w , .power_n_evenov w {left: 186px; top: 18px; position: absolute; font-size: 20px; color: #E3CEF6;}
.power_p_evenun w1 , .power_p_evenov w1 , .power_n_evenun w1 , .power_n_evenov w1 {left: 182px; top: 19px; position: absolute; font-size: 20px; color: #E3CEF6;}


.power_p_odd a:hover,
.power_p_odd.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_p_even a:hover,
.power_p_even.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_odd a:hover,
.power_n_odd.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_even a:hover,
.power_n_even.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_big a:hover,
.power_n_big.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_middle a:hover,
.power_n_middle.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_small a:hover,
.power_n_small.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_oddbig a:hover,
.power_n_oddbig.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_oddmiddle a:hover,
.power_n_oddmiddle.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_oddsmall a:hover,
.power_n_oddsmall.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_evenbig a:hover,
.power_n_evenbig.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_evenmiddle a:hover,
.power_n_evenmiddle.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_evensmall a:hover,
.power_n_evensmall.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}

.power_p_un a:hover,
.power_p_un.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_p_ov a:hover,
.power_p_ov.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_un a:hover,
.power_n_un.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_ov a:hover,
.power_n_ov.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_sid_a a:hover,
.power_sid_a.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_sid_b a:hover,
.power_sid_b.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_sid_c a:hover,
.power_sid_c.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_sid_d a:hover,
.power_sid_d.selected a{background:url('~@/assets/img/etc/btn_bg1.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}

.power_p_oddun a:hover,
.power_p_oddun.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_p_oddov a:hover,
.power_p_oddov.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_p_evenun a:hover,
.power_p_evenun.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_p_evenov a:hover,
.power_p_evenov.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_oddun a:hover,
.power_n_oddun.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_oddov a:hover,
.power_n_oddov.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_evenun a:hover,
.power_n_evenun.selected a{background:url('~@/assets/img/etc/btn_bg_under.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}
.power_n_evenov a:hover,
.power_n_evenov.selected a{background:url('~@/assets/img/etc/btn_bg_over.jpg') center center no-repeat; background-size: cover; box-shadow: 0px 10px 30px rgba(0,0,0,0.7);}

</style>
