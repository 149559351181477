<template>
  <div>
    <div class="popup_blank" v-if="popup_bg">
      <!--점검중-->
      <template v-for="(popup, index) in event_popup">
        <div class="new_message_popup2"
             style="margin-top: 100px;"
             v-if="isVisible(popup)"
             :key="'modalPopup'+index">
          <div class="bg_mask_pop_title">
            <span class="popup_logo">
              <img style="width: 100px;" src="@/assets/img/main/kiki_logo_main.png">
            </span>
            <span class="popup_close closeLogoutBtn" @click="closePopup(popup)">
              <img src="@/assets/img/main/popup_close_one.png">
            </span>
          </div>
          <div class="popup_content" style="padding: 20px">
            <div v-html="popup.url">
            </div>
          </div>
          <div class="bg_mask_pop_title" style="float: right;">
            <span style="cursor: pointer; color:gray;" @click="setInVisible(popup)">24시간동안 보이지 않기</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  data: function(){
    return {
      popupBg: false,
    }
  },
  computed: {
    event_popup: function(){
      return this.$store.state.event.event_popup;
    },
    storagePopups(){
      const storagePopup = localStorage.getItem('popups');
      const popups = JSON.parse(storagePopup) || [];
      return popups;
    },
    popup_bg: function(){
      if (this.storagePopups.length === 0) return this.$store.getters['EVENT_POPUP_BG'];
      return this.storagePopups.filter(e => !e.isSetDate && e.isVisible).length > 0
    },
  },
  methods: {
    closePopup(popup){
      popup.isVisible = false;
    },
    setInVisible(popup){
      const date = new Date();
      date.setDate(date.getDate() + 1);
      popup.isSetDate = date;
      popup.isVisible = false;
      localStorage.setItem('popups', JSON.stringify(this.event_popup));
    },
    isVisible: function (popup) {
      let val = false;
      const p = this.storagePopups.find(e => e.seq === popup.seq);
      if (!popup.isVisible) return val;
      if (!p) return true;
      if (!p && p.isSetDate) return true;
      if (p.isSetDate && new Date(p.isSetDate) < new Date()) return true;
      if (p.isSetDate && !p.isVisible) return false;

      if (!popup.isSetDate && popup.isVisible) return true;
      if (popup.isSetDate && new Date(popup.isSetDate) < new Date()) return true;
      return val;
    },
  }
}
</script>

<style scoped>

</style>
